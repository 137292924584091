//
// Plugins: JQVMap
//

// Zoom Button size fixes
.jqvmap-zoomin,
.jqvmap-zoomout {
  background-color: $button-default-background-color;
  border: 1px solid $button-default-border-color;
  border-radius: $btn-border-radius;
  color: $button-default-color;
  height: 15px;
  width: 15px;

  &:hover,
  &:active,
  &.hover {
    background-color: darken($button-default-background-color, 5%);
    color: darken($button-default-color, 10%);
  }
}
