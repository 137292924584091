//
// Plugin: Bootstrap Slider
//

// Style override
.slider {
  &.slider-vertical {
    height: 100%;
  }
  &.slider-horizontal {
    width: 100%;
  }
}

// Colors
@each $name, $color in $theme-colors {
  .slider-#{$name} .slider {
    .slider-selection {
      background: $color;
    }
  }
}

@each $name, $color in $colors {
  .slider-#{$name} .slider {
    .slider-selection {
      background: $color;
    }
  }
}
