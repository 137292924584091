//
// Component: Carousel
//

.carousel-control {
  &.left,
  &.right {
    background-image: none;
  }

  > .fa,
  > .fas,
  > .far,
  > .fab,
  > .glyphicon,
  > .ion {
    display: inline-block;
    font-size: 40px;
    margin-top: -20px;
    position: absolute;
    top: 50%;
    z-index: 5;
  }
}
