//
// Plugins: Miscellaneous
// Old plugin codes
//

// _fix for sparkline tooltip
.jqstooltip {
  height: auto !important;
  padding: 5px !important;
  width: auto !important;
}

// jQueryUI
.connectedSortable {
  min-height: 100px;
}

.ui-helper-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.sort-highlight {
  background: $gray-100;
  border: 1px dashed $gray-300;
  margin-bottom: 10px;
}

// Charts
.chart {
  overflow: hidden;
  position: relative;
}
